<template>
    <div class="decision">
        <el-card>
            <el-row :gutter="20" class="operate">
                <el-col :span="4">
                    <el-select v-model="selectType" placeholder="请选择类型" filterable clearable>
                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="selectInputWay" placeholder="请选择字段类型" filterable clearable>
                        <el-option v-for="(item, index) in inputWayList" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-input v-model="selectName" placeholder="请输入配置名称" clearable></el-input>
                </el-col>
                <el-button @click="search" icon="el-icon-search" type="primary"></el-button>
            </el-row>
            <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
                <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
                <el-table-column prop="name" label="名称" width="200"></el-table-column>
                <el-table-column prop="type_name" label="类型" width="150"></el-table-column>
                <el-table-column prop="input_way_name" label="字段类型" width="150"></el-table-column>
                <el-table-column prop="range_set" label="字段范围" width="200"></el-table-column>
                <el-table-column prop="value" label="值"></el-table-column>
                <el-table-column label="状态" width="200">
                    <template slot-scope="scope"><span class="status_text">{{scope.row.status | statusText}}</span></template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                    <template #default="scope">
                        <el-button @click="openDialog(scope.row)" type="warning" size="mini">设置</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

            <!-- 添加弹窗 -->
            <el-dialog @close="closeDialog('setDecisionDialog')" title="设置决策值" :visible.sync="showDialog" class="add_dialog">
                <el-form :model="form" ref="setDecisionDialog" :rules="rules">
                    <el-form-item label="决策名" prop="name" :label-width="formLabelWidth">
                        <el-col :span="16">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="决策值" prop="value" :label-width="formLabelWidth">
                        <el-col :span="16">
                            <!--int(ExclusiveChoiceType)1: "单选",
                            int(MultipleChoiceType)2:  "多选",
                            int(TextType)3:            "文本",
                            int(NumberType)4:          "数字",
                            int(DepartmentType)5:      "部门",
                            int(CategoryType)6:        "分类",
                            int(DateType)7:            "日期",-->
                            <el-input v-if="form.input_way === 3 || form.input_way === 4" v-model="form.value" autocomplete="off"></el-input>
                            <el-select v-if="form.input_way === 1" v-model="form.value" placeholder="请选择">
                                <el-option
                                        v-for="(item, index) in options"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            <el-checkbox-group v-if="form.input_way === 2" v-model="checkList" @change="handleCheckboxChange">
                                <el-checkbox v-for="(item, index) in options" :key="index" :label="item.value">{{item.name}}</el-checkbox>
                            </el-checkbox-group>
                            <el-cascader v-if="form.input_way === 5 || form.input_way === 6"
                                    v-model="checkList"
                                    :options="options"
                                    :props="{ expandTrigger: 'hover', multiple: true, checkStrictly: true }"
                                    @change="handleCascadeChange" clearable style="width:100%;"></el-cascader>
                            <el-date-picker v-if="form.input_way === 7"
                                    v-model="form.value"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="选择日期时间">
                            </el-date-picker>
                        </el-col>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button @click="setDecision('setDecisionDialog')" type="primary">确 定</el-button>
                </div>
            </el-dialog>

        </el-card>
    </div>
</template>

<script>
    import {getDecisionList, getDecisionTypeAll, getInputWayAll, setDecisionItem, getProfileValues, getDepartmentCascade, getCateCascade} from '@api';

    export default {
        name: 'Decision',
        data() {
            return {
                params: { // 请求分页参数
                    page: 1,
                    page_size: 10
                },
                total: 0, // 数据总条数
                tableData: [], // 表格数据
                selectName: '', // 搜索名称
                selectType: '', // 搜索类型
                selectInputWay: '', // 搜索值类型
                typeList: [], // 类型数据
                inputWayList: [], // 字段类型数据
                loading: true, // 表格加载等待
                showDialog: false, // 是否显示弹窗
                form: { // 添加表单
                    set_id: 0,
                    name: '',
                    decision_id: 0,
                    input_way: 3,
                    range_set: '',
                    value: '',
                },
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                    ],
                    value: [
                        {required: true, message: '请输入配置值', trigger: 'blur'},
                    ],
                },
                checkList: [],
                options: [],
                formLabelWidth: '120px', // from表单label的宽度
            }
        },
        created() {
            this.getData(this.params);
            getDecisionTypeAll().then(res => this.typeList = res.data);
            getInputWayAll().then(res => this.inputWayList = res.data);
        },
        filters: {
            statusText(status) {
                if (status > 0) {
                    return '已审核';
                }
                return '未审核';
            }
        },
        methods:{
            search() { // 搜索
                const {selectName, selectType, selectInputWay} = this;
                this.params.name = selectName;
                this.params.type = selectType;
                this.params.input_way = selectInputWay;
                this.getData(this.params);
            },
            handleCurrentChange(val) { // 获取页数
                this.params.page = val;
                this.getData(this.params);
            },
            handleSizeChange(val) { // 获取每页条数
                this.params.page_size = val;
                this.getData(this.params);
            },
            getData(params) {
                this.loading = true;
                getDecisionList(params).then(res => {
                    this.loading = false;
                    const {data, count} = res.data;
                    this.total = count;
                    this.tableData = data;
                }).catch(() => {
                    this.loading = false;
                })
            },
            openDialog(item) { // 打开弹窗
                this.form = { ...item };
                this.showDialog = true;
                this.checkList = [];
                if (this.form.input_way === 2 && this.form.value.length) {
                    this.checkList = this.form.value.split(';');
                }
                if ((this.form.input_way === 5 || this.form.input_way === 6) && this.form.value.length) {
                    let values = [];
                    let arr = this.form.value.split(';');
                    arr.forEach((value) => {
                        values.push(value.split('-'));
                    });
                    this.checkList = values;
                }
                if (this.form.input_way === 1 || this.form.input_way === 2) {
                    if (this.form.range_set.length > 0) {
                        let regexp = /^profile:\d+;[^\x00-\xff]+/;
                        if (regexp.test(this.form.range_set)) {
                            let arr = this.form.range_set.split(';');
                            if (arr.length === 2) {
                                let name = arr[1];
                                let types = arr[0].split(':');
                                if (types.length === 2) {
                                    let type = types[1];
                                    getProfileValues({type: type, name: name}).then(res => {
                                        this.options = res.data;
                                    })
                                }
                            }
                        } else {
                            try {
                                this.options = JSON.parse(this.form.range_set);
                            } catch (e) {
                                console.log(e);
                                this.options = [];
                            }
                        }
                    }
                }
                if (this.form.input_way === 5) {
                    getDepartmentCascade().then(res => {
                        this.options = res.data;
                    })
                }
                if (this.form.input_way === 6) {
                    getCateCascade().then(res => {
                        this.options = res.data;
                    })
                }
                if (this.form.input_way === 7) {
                    this.form.value = new Date(this.form.value);
                }
            },
            handleCascadeChange(values) {
                let value = [];
                values.forEach((items) => {
                    value.push(items.join('-'));
                });
                this.form.value = value.join(';');
                console.log(this.form.value);
            },
            handleCheckboxChange(values) {
                this.form.value = values.join(';');
                console.log(this.form.value);
            },
            closeDialog(refName) { // 关闭弹窗
                this.showDialog = false;
                this.$refs[refName].resetFields();
            },
            cancel() {
                this.showDialog = false;
            },
            setDecision(refName) {
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    let set_id = this.form.set_id;
                    let decision_id = this.form.id;
                    this.form.id = set_id;
                    this.form.decision_id = decision_id;
                    setDecisionItem(this.form).then(() => {
                        this.showDialog = false;
                        this.$message.success('修改成功！');
                        this.getData(this.params);
                    });
                });
            }
        },
    }
</script>

<style lang="less" scoped>

</style>